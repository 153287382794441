<template>
    <div class="page">
        <div class="matter" v-if="productItem != null">
            <div class="body">
                <div class="title">请选择邮寄地址</div>
                <div class="addressInfo" v-if="addressInfo === null" @click="goAddress">
                    <div class="choose">选择收货地址</div>
                    <img class="images" src="../assets/icon.png"/>
                </div>
                <ConvertAddressItem v-else :item="addressInfo" v-on:chooseAddress="goAddress"/>
                <div class="title">礼品订单信息</div>
                <ConvertDetailItem :item="productItem"/>
            </div>
            <div class="bottom">
                <BottomCom/>
            </div>
        </div>
        <div class="footer">
            请选择收货地址并确认收货地址无误 <span @click="goOrder">确认</span>
        </div>
    </div>
</template>

<script>
    import BottomCom from "../components/BottomCom";
    import ConvertDetailItem from "../components/ConvertDetailItem";
    import {getConvertDetailList, getProductGroup, submitProduct} from "../api/data";
    import _ from "lodash";
    import {Toast} from "vant";
    import ProductModal from "../components/ProductModal";
    import ConvertAddressItem from "../components/ConvertAddressItem";

    export default {
        name: "MatterSubmit",
        components: {ConvertAddressItem, ProductModal, ConvertDetailItem, BottomCom},
        data: function () {
            return {
                productItem: null,
                addressInfo: null,
            }
        },
        async beforeMount() {
            let productInfo = localStorage.getItem("productItem");
            if (productInfo) {
                this.productItem = JSON.parse(productInfo)
            }
            let addressInfo = localStorage.getItem("addressInfo");
            if (addressInfo) {
                console.log(addressInfo);
                this.addressInfo = JSON.parse(addressInfo)
            }
        },
        methods: {
            async goHome() {
                await this.$router.push('/')
            },
            async goOrder() {
                let param = {
                    id: this.productItem.id,
                    goods_id: this.productItem.goods_id,
                    address_id: this.addressInfo.id,
                };
                // let res = {code: 1, data: {order_on: "2021070817261757989754", order_id: "1"}}
                let res = await submitProduct(param);
                if (_.toInteger(res.code) === 1) {
                    localStorage.removeItem("productItem")
                    await this.$router.push({name: 'Success', query: {orderId: res.data.order_on}});
                } else {
                    Toast.fail(res.msg);
                }
            },
            async goAddress() {
                await this.$router.push({name: 'Address'});
            },

            async clickConvertDetail(id, goodId, type) {
                if (_.toInteger(type) === 2) {
                    let param = {
                        id: _.toInteger(id),
                        goods_id: _.toInteger(goodId)
                    };
                    let res = await getProductGroup(param);
                    if (_.toInteger(res.code) === 1) {
                        this.proList = res.data.data;
                        this.productModal = true;
                    } else {
                        Toast.fail(res.msg);
                    }
                } else {
                    await this.$router.push({name: 'ProductDetail', query: {id: _.toString(id), goodId: _.toString(goodId)}});
                }
            }
        }
    }
</script>

<style scoped>
    .matter {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 690px;
        justify-content: flex-start;
        align-items: center;
        padding: 24px;
        box-sizing: border-box;
    }

    .matter .body {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 20px;
    }

    .matter .body .title {
        padding: 0 10px;
        height: 42px;
        font-size: 30px;
        font-weight: bold;
        color: #A05923;
        margin-bottom: 20px;
    }

    .matter .body .addressInfo {
        width: 642px;
        height: 228px;
        background: url("../assets/goog_bg.png") no-repeat left top;
        background-size: 100% auto;
        margin-bottom: 25px;
        position: relative;
        box-sizing: border-box;
        padding: 35px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
    }

    .matter .body .images {
        width: 8px;
        height: 18px;
    }

    .matter .body .addressInfo .choose {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: 500;
        color: #E8C8AF;
    }

    .matter .bottom {
        width: 642px;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .page .footer {
        position: fixed;
        bottom: 0;
        background: #FFFFFF;
        width: 690px;
        height: 120px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
    }

    .page .footer span {
        width: 215px;
        height: 80px;
        background: linear-gradient(125deg, #3A3D43 0%, #202327 100%);
        border-radius: 40px;
        font-size: 30px;
        font-weight: bold;
        color: #E2BE93;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
