<template>
    <div class="convertAddressItem" @click="changeAddress">
        <div class="body">
            <div class="top van-ellipsis">{{item.name}} <span>{{item.phone}}</span> <span v-if="item.default === '1'">默认</span></div>
            <div class="desc van-multi-ellipsis--l2">
                {{item.province_name+item.city_name+item.area_name+item.address}}
            </div>
        </div>
        <div class="image">
            <img src="../assets/icon.png"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ConvertAddressItem",
        props: ['item'],
        methods: {
            changeAddress() {
                this.$emit("chooseAddress")
            }
        }
    }
</script>

<style scoped>
    .convertAddressItem {
        width: 642px;
        height: 228px;
        background: url("../assets/goog_bg.png") no-repeat left top;
        background-size: 100% auto;
        margin-bottom: 25px;
        position: relative;
        box-sizing: border-box;
        padding: 35px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
    }

    .convertAddressItem .body {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .convertAddressItem .body .top {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 30px;
        color: #E8C8AF;
    }

    .convertAddressItem .body .top span {
        margin-left: 20px;
    }

    .convertAddressItem .body .top span:last-child {
        height: 32px;
        background: #F9EDDE;
        border-radius: 16px;
        padding: 0 15px;
        font-size: 22px;
        color: #171A1D;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .convertAddressItem .body .desc {
        margin-top: 15px;
        font-size: 26px;
        font-weight: 400;
        line-height: 38px;
        color: #B19485;
    }

    .convertAddressItem .image {
        margin-left: 20px;
        width: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .convertAddressItem .image img {
        width: 8px;
        height: 18px;
    }
</style>
